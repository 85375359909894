import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';

const HSRGuidesElementsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Elements</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_elements.png"
            alt="Elements"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Elements</h1>
          <h2>The elemental system in Honkai: Star Rail explained.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Elements" />
        <p>
          Each Character in Honkai: Star Rail has an <strong>Element</strong>{' '}
          associated with them. A Character’s Element determines the damage type
          of their attacks.
        </p>
        <p>There are currently 7 Elements in the game:</p>
        <ul>
          <li>
            <span className="hsr-dmg phys">Physical</span>
          </li>
          <li>
            <span className="hsr-dmg fire">Fire</span>
          </li>
          <li>
            <span className="hsr-dmg ice">Ice</span>
          </li>
          <li>
            <span className="hsr-dmg lightning">Lightning</span>
          </li>
          <li>
            <span className="hsr-dmg wind">Wind</span>
          </li>
          <li>
            <span className="hsr-dmg quantum">Quantum</span>
          </li>
          <li>
            <span className="hsr-dmg imaginary">Imaginary</span>
          </li>
        </ul>
        <SectionHeader title="Elements and combat" />
        <p>
          Enemies have a set of elemental weaknesses (generally 3) which are
          displayed above their head.{' '}
          <strong>
            Unlike a traditional RPG game, enemies do not take additional damage
            when they are hit by attacks of an element that they are weak
            against
          </strong>
          . Instead, doing so will reduce the enemy’s Toughness instead. You can
          inflict Weakness Break on an enemy by reducing its Toughness to 0. An
          enemy that has been Weakness Broken will suffer additional damage and
          a debuff based on the element of the attack that reduced its Toughness
          to 0.
        </p>
        <p>The Weakness Break for each element are as follows:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Element</th>
              <th>Damage</th>
              <th>Debuff</th>
              <th>Debuff info</th>
              <th>Is DoT?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="hsr-dmg phys">Physical</span>
              </td>
              <td>Highest</td>
              <td>Bleed</td>
              <td>
                Deals damage over time (based on enemy Max HP) lasting for 2
                turns.
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg fire">Fire</span>
              </td>
              <td>Highest</td>
              <td>Burn</td>
              <td>Deals damage over time lasting for 2 turns.</td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg wind">Wind</span>
              </td>
              <td>High</td>
              <td>Wind Shear</td>
              <td>
                Deals damage over time lasting for 2 turns (can stack up to 5
                times).
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg lightning">Lightning</span>
              </td>
              <td>Average</td>
              <td>Shock</td>
              <td>Deals damage over time lasting for 2 turns.</td>
              <td>
                <FontAwesomeIcon icon={faCheck} className="green" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg ice">Ice</span>
              </td>
              <td>Average</td>
              <td>Freeze</td>
              <td>
                Deals damage and Freezes the enemy for 1 turn. After enemy is
                unfrozen, he skips a turn, but advances his action forward by
                50%.
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg quantum">Quantum</span>
              </td>
              <td>Low</td>
              <td>Entanglement</td>
              <td>
                Deals damage (based on enemy max Toughness) and Delays the enemy
                action based on Break Effect stat. Applies 1 stack whenever the
                enemy is hit (up to 5 stacks).
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
            <tr>
              <td>
                <span className="hsr-dmg imaginary">Imaginary</span>
              </td>
              <td>None</td>
              <td>Imprisonment</td>
              <td>
                Delays the enemy action based on Break Effect stat and reduces
                their Speed by 10% for 1 turn.
              </td>
              <td>
                <FontAwesomeIcon icon={faXmark} className="red" width="22" />
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          While broken, the enemy’s Toughness remains at 0 and further attacks
          of an element that the enemy is weak against will have no additional
          effects on it. A broken enemy will recover from its broken state on
          its next turn. Its Toughness will also be replenished back to full and
          it can be Weakness Broken again.
        </p>
        <SectionHeader title="Elements and characters" />
        <p>
          Below you will find a list of all characters available in the game
          grouped by the Element they wield.
        </p>
        <h5>
          <span className="hsr-dmg phys">Physical</span> characters
        </h5>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="argenti" enablePopover />
          <HSRCharacter mode="icon" slug="boothill" enablePopover />
          <HSRCharacter mode="icon" slug="clara" enablePopover />
          <HSRCharacter mode="icon" slug="hanya" enablePopover />
          <HSRCharacter mode="icon" slug="luka" enablePopover />
          <HSRCharacter mode="icon" slug="natasha" enablePopover />
          <HSRCharacter mode="icon" slug="robin" enablePopover />
          <HSRCharacter mode="icon" slug="sushang" enablePopover />
          <HSRCharacter mode="icon" slug="trailblazer-physical" enablePopover />
        </div>
        <h5>
          <span className="hsr-dmg fire">Fire</span> characters
        </h5>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="asta" enablePopover />
          <HSRCharacter mode="icon" slug="firefly" enablePopover />
          <HSRCharacter mode="icon" slug="gallagher" enablePopover />
          <HSRCharacter mode="icon" slug="guinaifen" enablePopover />
          <HSRCharacter mode="icon" slug="himeko" enablePopover />
          <HSRCharacter mode="icon" slug="hook" enablePopover />
          <HSRCharacter mode="icon" slug="jiaoqiu" enablePopover />
          <HSRCharacter mode="icon" slug="lingsha" enablePopover />
          <HSRCharacter mode="icon" slug="topaz" enablePopover />
          <HSRCharacter mode="icon" slug="trailblazer-fire" enablePopover />
        </div>
        <h5>
          <span className="hsr-dmg ice">Ice</span> characters
        </h5>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="gepard" enablePopover />
          <HSRCharacter mode="icon" slug="herta" enablePopover />
          <HSRCharacter mode="icon" slug="jingliu" enablePopover />
          <HSRCharacter mode="icon" slug="march-7th" enablePopover />
          <HSRCharacter mode="icon" slug="misha" enablePopover />
          <HSRCharacter mode="icon" slug="pela" enablePopover />
          <HSRCharacter mode="icon" slug="ruan-mei" enablePopover />
          <HSRCharacter mode="icon" slug="yanqing" enablePopover />
        </div>
        <h5>
          <span className="hsr-dmg lightning">Lightning</span> characters
        </h5>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="acheron" enablePopover />
          <HSRCharacter mode="icon" slug="arlan" enablePopover />
          <HSRCharacter mode="icon" slug="bailu" enablePopover />
          <HSRCharacter mode="icon" slug="jing-yuan" enablePopover />
          <HSRCharacter mode="icon" slug="kafka" enablePopover />
          <HSRCharacter mode="icon" slug="moze" enablePopover />
          <HSRCharacter mode="icon" slug="serval" enablePopover />
          <HSRCharacter mode="icon" slug="tingyun" enablePopover />
        </div>
        <h5>
          <span className="hsr-dmg wind">Wind</span> characters
        </h5>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="black-swan" enablePopover />
          <HSRCharacter mode="icon" slug="blade" enablePopover />
          <HSRCharacter mode="icon" slug="bronya" enablePopover />
          <HSRCharacter mode="icon" slug="dan-heng" enablePopover />
          <HSRCharacter mode="icon" slug="feixiao" enablePopover />
          <HSRCharacter mode="icon" slug="huohuo" enablePopover />
          <HSRCharacter mode="icon" slug="sampo" enablePopover />
        </div>
        <h5>
          <span className="hsr-dmg quantum">Quantum</span> characters
        </h5>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="fu-xuan" enablePopover />
          <HSRCharacter mode="icon" slug="jade" enablePopover />
          <HSRCharacter mode="icon" slug="lynx" enablePopover />
          <HSRCharacter mode="icon" slug="qingque" enablePopover />
          <HSRCharacter mode="icon" slug="seele" enablePopover />
          <HSRCharacter mode="icon" slug="silver-wolf" enablePopover />
          <HSRCharacter mode="icon" slug="sparkle" enablePopover />
          <HSRCharacter mode="icon" slug="xueyi" enablePopover />
        </div>
        <h5>
          <span className="hsr-dmg imaginary">Imaginary</span> characters
        </h5>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="aventurine" enablePopover />
          <HSRCharacter mode="icon" slug="imbibitor-lunae" enablePopover />
          <HSRCharacter mode="icon" slug="dr-ratio" enablePopover />
          <HSRCharacter mode="icon" slug="luocha" enablePopover />
          <HSRCharacter
            mode="icon"
            slug="march-7th-swordmaster"
            enablePopover
          />
          <HSRCharacter mode="icon" slug="welt" enablePopover />
          <HSRCharacter mode="icon" slug="yukong" enablePopover />
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesElementsPage;

export const Head: React.FC = () => (
  <Seo
    title="Elements | Honkai: Star Rail | Prydwen Institute"
    description="The elemental system in Honkai: Star Rail explained."
  />
);
